import React from 'react';
import { Text, Link } from '@arcadiapower/shrike';
import { copyFor } from 'config';
import { Footer as StyledFooter } from 'styles/form.style';

const getCopy = copyFor('login');

export const Footer = () => (
  <StyledFooter>
    <Text>
      {getCopy('footer')}
      <Link href={process.env.ARCADIA_FUNNEL_URL}>
        {getCopy('links.signUp')}
      </Link>
    </Text>
  </StyledFooter>
);
