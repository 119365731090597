import React from 'react';
import { Button, Link } from '@arcadiapower/shrike';
import { copyFor, routes } from 'config';
import { Footer as StyledFooter } from 'styles/form.style';

const getCopy = copyFor('emailLogin');

interface Props {
  disabled: boolean;
  loading: boolean;
}

export const Footer = ({ disabled, loading }: Props) => (
  <StyledFooter>
    <Button
      fullWidth
      disabled={disabled}
      type="submit"
      loading={loading}
      margin={{ bottom: '16px' }}
    >
      {getCopy('buttons.signIn')}
    </Button>
    <Link to={routes.passwordlessAuth}>{getCopy('links.forgotPassword')}</Link>
  </StyledFooter>
);
