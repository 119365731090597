import gql from 'graphql-tag';

export const GET_PROMO_CODE = gql`
  query GetPromoCode($promoCode: String!) {
    userPromoCode(promoCode: $promoCode) {
      promoText
    }
  }
`;

export const getPromoCodeMock = {
  userPromoCode: {
    promoText: 'Sign up today to rescue some big cats!',
  },
};
