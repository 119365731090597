import styled, { css } from 'styled-components';

const containerStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.theme.media.small`
    justify-content: space-between;
  `}
`;

export const Form = styled.form`
  ${containerStyles}

  input {
    max-width: initial;
  }
`;

export const Wrapper = styled.div`
  ${containerStyles}
`;

export const Footer = styled.div`
  text-align: center;
`;
