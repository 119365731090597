import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from './app';
import '@arcadiapower/shrike/dist/shrike.css';

const env = import.meta.env.MODE;

if (['production', 'staging'].includes(env)) {
  Sentry.init({
    dsn: process.env.ARCADIA_SENTRY_DSN,
    environment: env,
    ignoreErrors: ['Non-Error promise rejection captured with value:'],
    integrations: [new BrowserTracing()],
    tracesSampler: samplingContext => {
      if (
        env === 'production' &&
        samplingContext.transactionContext.op === 'pageload'
      ) {
        return 0.75;
      } else {
        return 0;
      }
    },
  });
}

if (env !== 'production') {
  // shows everywhere but production - remove this if block once Spanish goes live
  window.Localize.showWidget();
}

ReactDOM.render(<App />, document.getElementById('root'));
