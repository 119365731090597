import React from 'react';
import { Input } from '@arcadiapower/shrike';
import { copyFor } from 'config';

const getCopy = copyFor('emailLogin.fields.password');

interface Props {
  margin?: Margin;
  onChange: (val: string) => void;
  value: string;
}

export const Password = ({ margin, onChange, value }: Props) => (
  <Input
    label={getCopy('label')}
    margin={margin}
    name="password"
    onChange={onChange}
    type="password"
    value={value}
    placeholder={getCopy('placeHolder')}
  />
);
