import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { getQueryParams, addQueryParams } from '@arcadiapower/warbler';
import { routes } from 'config';
import { useAsync } from 'utils/hooks/use-async';
import { Error } from 'components/error';
import { APPLY_DORMANT_PROMO_CODE } from './promo-code-handler.api';

const { passwordlessAuth } = routes;

interface Props {
  location: Location;
}

// This component doesn't render anything unless there's an error.
// It applies a dormant promo code and then redirects the user to the forgot password page.
export const PromoCodeHandler = (props: Props) => {
  const history = useHistory();
  const client = useApolloClient();
  const queryParams = getQueryParams<{
    email: string | undefined;
    promoCode: string | undefined;
  }>(props.location.search);
  const { email, promoCode } = queryParams;
  const passwordlessAuthRouteWithParams = addQueryParams(passwordlessAuth, {
    email,
    promoCode,
  });

  const [{ error, data }, applyDormantPromoCode] = useAsync(() =>
    client.mutate<
      ApplyDormantPromoCodeMutation,
      ApplyDormantPromoCodeMutationVariables
    >({
      mutation: APPLY_DORMANT_PROMO_CODE,
      variables: {
        input: { email: email || '', promoCode: promoCode || '' },
      },
    })
  );

  useEffect(() => {
    if (email && promoCode) {
      applyDormantPromoCode();
    }
  }, []);

  useEffect(() => {
    if (data) {
      history.push(passwordlessAuthRouteWithParams);
    }
  }, [data]);

  if (error) return <Error error={error} />;

  return null;
};
