// import deepmerge from 'deepmerge';
import i18n from 'i18n-js';
import { base } from './base';

/*
config shape:
{
  copy,
  logo,
  routes,
  theme,
}
*/

// TODO - update once we have multiple templates
const config = base;

i18n.translations = config.copy;

export const copyFor = scope => (property, ...args) =>
  i18n.t(`${scope}.${property}`, ...args);

export const { routes } = config;
export const { theme } = config;
export const Logo = config.logo;
