import { useState } from 'react';
import { identity } from 'lodash-es';

export const useAsync = (fn, parseError = identity): any => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  const asyncFn = async (...params) => {
    setError(null);
    setLoading(true);
    try {
      const data = await fn(...params);
      setData(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(parseError(e));
    }
  };

  return [{ data, error, loading }, asyncFn];
};
