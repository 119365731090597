import * as Sentry from '@sentry/react';

const addUserToSentryScope = ({ email, id }) => {
  Sentry.configureScope(scope => {
    scope.setUser({ email, id });
  });
};

const identifyUserToSegment = ({ email, id }) => {
  try {
    if (window.analytics) window.analytics.identify(id, { email });
  } catch (e) {
    console.error(e);
  }
};

export const captureError = ({ error, operation }) => {
  Sentry.withScope(scope => {
    scope.setExtras({ operation });
    const errorMessage = error?.message;
    scope.setFingerprint([errorMessage]);
    Sentry.captureException(error);
  });
};

export const identifyUserToThirdParties = ({ email, id }) => {
  identifyUserToSegment({ email, id });
  addUserToSentryScope({ email, id });
};

export const trackPageView = () => {
  // window.analytics is the global Segment object
  if (window.analytics) window.analytics.page();
};
