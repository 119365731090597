import styled from 'styled-components';

export const Separator = styled.div`
  display: flex;
  margin: 16px 0;

  hr {
    border: none;
    border-bottom: ${props =>
      `solid 1px ${props.theme.colors.background.controls}`};
    flex-grow: 1;
  }

  p {
    color: ${props => props.theme.colors.content.secondary};
    margin: 0 8px;
  }
`;

export const Content = styled.div`
  margin: 48px 0;
`;
