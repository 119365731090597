import gql from 'graphql-tag';

export const SEND_AUTH_EMAIL_MUTATION = gql`
  mutation SendAuthEmail($email: String!) {
    sendAuthEmail(email: $email) {
      message
    }
  }
`;

export const sendAuthEmailMock = {
  sendAuthEmail: {
    message: 'success',
  },
};
