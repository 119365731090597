import React from 'react';
import { useQuery } from '@apollo/client';
import { Loading } from '@arcadiapower/shrike';
import { StyledBanner } from '../styled-banner';
import { GET_PROMO_CODE } from './promo-code-banner.api';

interface Props {
  promoCode: string | null;
}

export const PromoCodeBanner = ({ promoCode }: Props) => {
  const { data, error, loading } = useQuery<
    GetPromoCodeQuery,
    GetPromoCodeQueryVariables
  >(GET_PROMO_CODE, {
    skip: !promoCode,
    variables: { promoCode: promoCode || '' },
  });

  if (loading)
    return (
      <StyledBanner backgroundColor="accent2">
        <Loading />
      </StyledBanner>
    );

  if (error || !data) return null;

  return (
    <StyledBanner backgroundColor="accent2">
      {data.userPromoCode.promoText}
    </StyledBanner>
  );
};
