import React from 'react';
import { Link, Text } from '@arcadiapower/shrike';
import { copyFor, routes } from 'config';

const getCopy = copyFor('googleLogin.enable');
const { login } = routes;

export const FooterLink = () => (
  <Text>
    <Link
      aria-label={getCopy('links.signIn.ariaLabel')}
      to={login}
      type="inline"
    >
      {getCopy('links.signIn.copy')}
    </Link>
    {getCopy('footer.text')}
  </Text>
);
