import React from 'react';
import { copyFor, routes } from 'config';
import { Link, Text } from '@arcadiapower/shrike';
import { Page } from 'components/page';
import { Footer, Wrapper } from 'styles/form.style';
import { StyledText } from './success.style';

const getCopy = copyFor('passwordlessAuth.success');
const { login } = routes;

interface Props {
  email: string;
}

export const Success = ({ email }: Props) => {
  const subtitle = getCopy('subtitle');
  return (
    <Page back={{ to: login }}>
      <Wrapper>
        <div>
          <Text textStyle="heading800" margin={{ bottom: '16px' }} tag="h1">
            {getCopy('title')}
          </Text>
          <StyledText opacity="medium" margin={{ bottom: '48px' }}>
            {subtitle}
            <span>{email}</span>.
          </StyledText>
        </div>
        <Footer>
          <Link
            type="CTA"
            fullWidth
            aria-label={getCopy('links.login.ariaLabel')}
            to={login}
          >
            {getCopy('links.login.copy')}
          </Link>
        </Footer>
      </Wrapper>
    </Page>
  );
};
