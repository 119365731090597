import React from 'react';
import { Text } from '@arcadiapower/shrike';
import { Banner } from './styled-banner.style';

interface Props {
  backgroundColor: string;
  children: React.ReactNode;
}

export const StyledBanner = ({ backgroundColor, children }: Props) => (
  <Banner role="alert" backgroundColor={backgroundColor}>
    {typeof children === 'string' ? <Text>{children}</Text> : children}
  </Banner>
);
