const en = {
  banner: {
    authExpired: 'Your %{expiredTopic} has expired. Please sign back in.',
  },
  login: {
    title: 'Sign in to Community Solar',
    description:
      'Access your community solar account with Google or your email address.',
    separator: 'or',
    footer: 'New to Arcadia? ',
    links: {
      email: 'Sign in with email',
      signUp: 'Sign up',
    },
  },
  googleLogin: {
    buttons: {
      signIn: 'Sign in with Google',
    },
    links: {
      login: 'Okay',
    },
    enable: {
      title: 'Sign in with Google',
      subtitle:
        'Looks like you haven’t signed in with Google before. Please continue below to enable this feature.',
      buttons: {
        submit: 'Continue with Google',
      },
      links: {
        signIn: {
          ariaLabel: 'Back to sign in',
          copy: 'Sign in',
        },
      },
      footer: {
        text: ' with another account',
      },
    },
  },
  emailLogin: {
    title: 'Sign in with email',
    description:
      'Enter your credentials to access your community solar account.',
    badge: 'Community solar',
    buttons: {
      signIn: 'Sign in',
    },
    links: {
      forgotPassword: 'Forgot password?',
    },
    fields: {
      email: {
        label: 'Email',
        placeHolder: 'name@example.com',
      },
      password: {
        label: 'Password',
        placeHolder: 'Enter your password',
      },
    },
  },
  passwordlessAuth: {
    badge: 'Community solar',
    forgotPassword: {
      title: 'Forgot password?',
      description:
        "No worries! Enter your email below, and we'll send you a link to access to your account.",
    },
    promoCode: {
      title: 'Login by email',
      description:
        'Enter the email that you used when you signed up for Arcadia, and we’ll send you an email with a secure link to finish setting up your account.',
    },
    buttons: {
      emailLink: 'Send link',
    },
    links: {
      login: 'Log in with password',
    },
    success: {
      title: "We've sent you a link to log in to your account.",
      subtitle:
        'If you have an account with us associated with that email address, you should receive an email with a login link at ',
      links: {
        login: {
          ariaLabel: 'Return to login',
          copy: 'Okay',
        },
      },
    },
  },
  shared: {
    backLink: {
      copy: 'Back',
      ariaLabel: 'Back to %{location}',
      locations: {
        '/': 'login',
        '/passwordless-auth': 'login link page',
      },
    },
    logo: 'Arcadia',
  },
  errors: {
    header: "Oops, that didn't work",
    client: {
      tokenExpirationError: 'Token is expired. Please re-enter credentials.',
      invalidEmailAddressError: 'Invalid email address',
    },
    server: {
      generic: 'Please try logging in again or contacting support@arcadia.com.',
      emailNotFound:
        'We do not have that email address on file. Please try a different email.',
      incorrectCredentials: 'Email address or password is incorrect',
      invalidEmail: 'Invalid email address',
      accountDeleted:
        'That account is not active. Please log in with a different account or contact support@arcadia.com for help.',
      promoCodeExpired:
        'That promo code has expired. Follow the link below to login.',
      ineligibleForPromo:
        'You are not eligible for this promotion at this time. Follow the link below to log in to your account or contact support@arcadia.com for help.',
    },
  },
};

export const copy = { en };
