import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import store from 'store';
import decode from 'jwt-decode';
import { isBefore } from '@arcadiapower/warbler';
import { TokenExpirationError } from 'utils/errors/client-errors';

export const GET_REDIRECT_URL = gql`
  query GetRedirectUrl($id: Int!, $app: ArcadiaApp, $path: String) {
    user(id: $id) {
      id
      redirectUrl(app: $app, path: $path)
    }
  }
`;

export const getRedirectUrlMock = {
  user: {
    id: 1,
    redirectUrl: 'http://redirect.com',
  },
};

export const useRedirect = authToken => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [redirect, { error, loading, data }] = useLazyQuery<
    GetRedirectUrlQuery,
    GetRedirectUrlQueryVariables
  >(GET_REDIRECT_URL);
  const [tokenError, setTokenError] = useState<
    TokenExpirationError | undefined
  >();
  const redirectUrl = data?.user.redirectUrl;

  useEffect(() => {
    if (authToken) {
      const { exp, sub } = decode(authToken);
      const id = Number(sub.split(':')[1]);

      if (isBefore(new Date(exp * 1000), new Date())) {
        setTokenError(new TokenExpirationError());
      }

      redirect({
        context: {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        },
        variables: {
          app: (searchParams.get('app') as ArcadiaApp) || undefined,
          id,
          path: store.get('redirectPath') || undefined,
        },
      });
    }
  }, [authToken]);

  useEffect(() => {
    if (redirectUrl) window.location.href = redirectUrl;
  }, [redirectUrl]);

  return { data, error: error || tokenError, loading };
};
