import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Alert, GoogleButton as Button } from '@arcadiapower/shrike';
import { copyFor } from 'config';
import { translateError } from 'utils/errors';
import { GET_GOOGLE_LOGIN_URL } from './google-button.api';

const getCopy = copyFor('googleLogin.buttons');

export const GoogleButton = () => {
  const [loadUrl, { data, loading, error }] = useLazyQuery<
    GoogleLoginUrlQuery,
    GoogleLoginUrlQueryVariables
  >(GET_GOOGLE_LOGIN_URL, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.googleLoginUrl) window.location.href = data.googleLoginUrl;
  }, [data]);

  return (
    <>
      <Button
        disabled={loading}
        fullWidth={true}
        loading={loading || !!data}
        onClick={() => loadUrl()}
      >
        {getCopy('signIn')}
      </Button>
      <Alert margin={{ top: '16px' }}>{translateError(error)}</Alert>
    </>
  );
};
