import { captureError } from '/src/utils/tracking';
/* eslint-disable */

const initOneTrust = () => {
  const key = import.meta.env.ARCADIA_ONETRUST_KEY;

  const firstScript = document.createElement('script');
  firstScript.type = 'text/javascript';
  firstScript.src = `https://cdn.cookielaw.org/consent/${key}/OtAutoBlock.js`;

  const secondScript = document.createElement('script');
  secondScript.type = 'text/javascript';
  secondScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  secondScript.setAttribute('data-domain-script', key);

  // OneTrust scripts must be first scripts in the head tag
  const head = document.getElementsByTagName('head')[0];
  head.insertBefore(secondScript, head.firstChild);
  head.insertBefore(firstScript, head.firstChild);
};
// Safely init OneTrust, so that if anything weird happens,
// we do not crash the application
try {
  initOneTrust();
} catch (e) {
  captureError({ error: e, message: 'Error initializing Onetrust' });
}
