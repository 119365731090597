import React from 'react';
import { Icon, Link } from '@arcadiapower/shrike';
import { copyFor } from 'config';
import { Wrapper } from './back-link.style';

const getCopy = copyFor('shared.backLink');

interface Props {
  className?: string;
  to: string;
}

export const BackLink = ({ className = '', to }: Props) => {
  const location = getCopy(`locations.${to}`);
  return (
    <Wrapper>
      <Link
        aria-label={getCopy('ariaLabel', { location })}
        className={className}
        hideIcon={true}
        to={to}
      >
        <Icon margin={{ right: '8px' }} icon="ChevronLeft" />
        {getCopy('copy')}
      </Link>
    </Wrapper>
  );
};
