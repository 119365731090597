import React from 'react';
import { useLocation } from 'react-router-dom';
import { copyFor, routes } from 'config';
import { PromoCodeBanner } from './promo-code-banner';
import { StyledBanner } from './styled-banner';

const getCopy = copyFor('banner');
const { passwordlessAuth } = routes;

export const Banner = () => {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const expiredTopic = searchParams.get('expired');
  const promoCode = searchParams.get('promoCode');
  const showPromoCode = pathname === passwordlessAuth && !!promoCode;

  if (!expiredTopic && !showPromoCode) return null;

  if (expiredTopic) {
    const expiredCopy = getCopy('authExpired', { expiredTopic });
    return (
      <StyledBanner backgroundColor="secondary">{expiredCopy}</StyledBanner>
    );
  } else if (showPromoCode) {
    return <PromoCodeBanner promoCode={promoCode} />;
  } else {
    return null;
  }
};
