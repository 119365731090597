import { copyFor } from 'config';

const getErrorCopy = copyFor('errors.server');

const serverErrors = [
  {
    copy: getErrorCopy('incorrectCredentials'),
    message: 'Incorrect email address or password',
  },
  {
    copy: getErrorCopy('accountDeleted'),
    message: 'Account deleted',
  },
  {
    copy: getErrorCopy('emailNotFound'),
    message: 'Email address not found',
  },
  {
    copy: getErrorCopy('invalidEmail'),
    message: 'Invalid email address',
  },
  {
    copy: getErrorCopy('promoCodeExpired'),
    message: 'Promo code expired',
  },
  {
    copy: getErrorCopy('ineligibleForPromo'),
    message: 'You are not eligible for a dormant bonus',
  },
];

export const getServerError = (message: string): string => {
  const error = serverErrors.find(serverError =>
    message.includes(serverError.message)
  );
  return error?.copy || getErrorCopy('generic');
};
