import React from 'react';
import { Text } from '@arcadiapower/shrike';
import { copyFor } from 'config';

const getCopy = copyFor('login');

export const Header = () => (
  <>
    <Text textStyle="heading800" margin={{ bottom: '16px' }} tag="h1">
      {getCopy('title')}
    </Text>
    <Text opacity="medium">{getCopy('description')}</Text>
  </>
);
