import gql from 'graphql-tag';

export const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      id
      token
    }
  }
`;

export const loginMock = token => ({
  userLogin: {
    id: 1,
    token,
  },
});
