import React, { useState } from 'react';
import { useMount } from 'react-use';
import { useApolloClient } from '@apollo/client';
import { identifyUserToThirdParties } from 'utils/tracking';
import { useRedirect } from 'utils/hooks/use-redirect';
import { useAsync } from 'utils/hooks/use-async';
import { Error as ErrorComponent } from 'components/error';
import { GOOGLE_LOGIN_ENABLED, THIRD_PARTY_LOGIN } from './google-login.api';
import { Enable } from './enable';

export const GoogleLogin = () => {
  const variables = {
    code: new URLSearchParams(window.location.search).get('code') || '',
    state: new URLSearchParams(window.location.search).get('state') || '',
  };

  const client = useApolloClient();
  const [token, setToken] = useState<string | undefined>();
  const [userId, setUserId] = useState<number | undefined>();
  const [showEnable, setShowEnable] = useState(false);

  const isEnabled = async ({ token, userId }) => {
    const { data } = await client.query<
      GoogleLoginEnabledQuery,
      GoogleLoginEnabledQueryVariables
    >({
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
      fetchPolicy: 'network-only',
      query: GOOGLE_LOGIN_ENABLED,
      variables: { id: userId },
    });
    if (data?.user)
      identifyUserToThirdParties({ email: data.user.email, id: data.user.id });
    setShowEnable(data?.user.preferences.enabledGoogleSignIn === false);
  };

  const [{ data, error }, authenticate] = useAsync(async () => {
    const { data, error } = await client.query<
      ThirdPartyUserLoginQuery,
      ThirdPartyUserLoginQueryVariables
    >({
      query: THIRD_PARTY_LOGIN,
      variables,
    });

    if (error) throw new Error(error.message);

    const queryToken = data?.thirdPartyUserLogin.token;
    const queryUserId = data?.thirdPartyUserLogin.id;

    setToken(queryToken);
    setUserId(queryUserId);

    await isEnabled({
      token: queryToken,
      userId: queryUserId,
    });

    return queryToken;
  });

  const { error: redirectError } = useRedirect(!showEnable && data);

  useMount(() => {
    authenticate();
  });

  if (error || redirectError) {
    return <ErrorComponent error={error} redirectError={redirectError} />;
  } else if (showEnable) {
    return <Enable token={token} userId={userId} isEnabled={isEnabled} />;
  } else {
    // TODO: add loading screen
    return null;
  }
};
