import { Logo } from '@arcadiapower/shrike';
import { copy } from './copy';
import { routes } from './routes';
import { theme } from './theme';

export const base = {
  copy,
  logo: Logo,
  routes,
  theme,
};
