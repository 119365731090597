import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { theme, routes } from 'config';
import { trackPageView } from 'utils/tracking';
import { Login } from './pages/login';
import { EmailLogin } from './pages/email-login';
import { GoogleLogin } from './pages/google-login';
import { PasswordlessAuth } from './pages/passwordless-auth';
import { PromoCodeHandler } from './pages/promo-code-handler';
import { GlobalStyle } from './styles/global.style';
import { client } from './apollo';

const Pages = () => {
  const location = useLocation();
  useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <Switch>
      <Route path={routes.googleLogin} component={GoogleLogin} />
      <Route path={routes.emailLogin} component={EmailLogin} />
      <Route path={routes.passwordlessAuth} component={PasswordlessAuth} />
      <Route path={routes.promoCode} component={PromoCodeHandler} />
      <Route exact path={routes.login} component={Login} />
      <Redirect to={routes.login} />
    </Switch>
  );
};

export const App = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Pages />
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>
);
