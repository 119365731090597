import { css } from 'styled-components';
import { arcadiaLate2021Theme, ShrikeTheme } from '@arcadiapower/shrike';

const breakpointXSmall = 599;
const breakpointSmall = 899;
const breakpointMedium = 1199;
const breakpointLarge = 1799;

const arcadia: ShrikeTheme = arcadiaLate2021Theme;

export const theme = {
  ...arcadia,
  media: {
    breakpoints: {
      xsmall: breakpointXSmall,
      small: breakpointSmall,
      medium: breakpointMedium,
      large: breakpointLarge,
    },
    // css.call(undefined, ...args) is a Typescript hack to let us call
    // css() with possibly undefined args
    large: (...args) => css`
      @media (max-width: ${breakpointLarge}px) {
        ${css.call(undefined, ...args)}
      }
    `,
    medium: (...args) => css`
      @media (max-width: ${breakpointMedium}px) {
        ${css.call(undefined, ...args)}
      }
    `,
    small: (...args) => css`
      @media (max-width: ${breakpointSmall}px) {
        ${css.call(undefined, ...args)}
      }
    `,
    xlarge: (...args) => css`
      @media (min-width: ${breakpointLarge + 1}px) {
        ${css.call(undefined, ...args)}
      }
    `,
    xsmall: (...args) => css`
      @media (max-width: ${breakpointXSmall}px) {
        ${css.call(undefined, ...args)}
      }
    `,
  },
};
