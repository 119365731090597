import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { getQueryParams } from '@arcadiapower/warbler';
import { Alert, Button, Text, Link, Badge } from '@arcadiapower/shrike';
import { copyFor, routes } from 'config';
import { useAsync } from 'utils/hooks/use-async';
import { translateError } from 'utils/errors';
import { InvalidEmailAddressError } from 'utils/errors/client-errors';
import { Page } from 'components/page';
import { Email } from 'components/fields/email';
import { Footer, Form } from 'styles/form.style';
import { SEND_AUTH_EMAIL_MUTATION } from './passwordless-auth.api';
import { Success } from './success';

const { login, emailLogin, passwordlessAuth } = routes;
const getCopy = copyFor('passwordlessAuth');

interface Props {
  location: Location;
}

export const PasswordlessAuth = (props: Props) => {
  const queryParams = getQueryParams(props.location.search);
  const initialEmail =
    typeof queryParams.email === 'string' ? queryParams.email : '';
  const [sendAuthEmail] = useMutation<
    SendAuthEmailMutation,
    SendAuthEmailMutationVariables
  >(SEND_AUTH_EMAIL_MUTATION);
  const [email, setEmail] = useState(initialEmail);
  const [
    clientError,
    setClientError,
  ] = useState<InvalidEmailAddressError | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const [{ error, loading }, submit] = useAsync(async () => {
    if (email) {
      await sendAuthEmail({ variables: { email } });
      setShowSuccess(true);
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (emailIsValid(email)) submit();
    else setClientError(new InvalidEmailAddressError());
  };

  if (showSuccess) return <Success email={email} />;

  // render generic login link copy for the dormant promo flow
  const copyKey = queryParams.promoCode ? 'promoCode' : 'forgotPassword';
  const errorObj = error || clientError;
  const errorMessage = translateError(errorObj);

  return (
    <Page back={{ to: login }}>
      <Form onSubmit={handleSubmit}>
        <div>
          <Badge backgroundColor="accent2">{getCopy('badge')}</Badge>
          <Text textStyle="heading800" margin={{ bottom: '16px' }} tag="h1">
            {getCopy(`${copyKey}.title`)}
          </Text>
          <Text margin={{ bottom: '40px' }} opacity="medium">
            {getCopy(`${copyKey}.description`)}
          </Text>
          <Email onChange={email => setEmail(email)} value={email} />
          {!errorMessage && <div style={{ height: '56px' }} />}
          <Alert margin="12px 0 20px">{errorMessage}</Alert>
        </div>
        <Footer>
          <Button
            disabled={!email}
            fullWidth
            type="submit"
            loading={loading}
            margin={{ bottom: '33px' }}
          >
            {getCopy('buttons.emailLink')}
          </Button>
          <Link
            to={{
              pathname: emailLogin,
              state: { from: passwordlessAuth },
            }}
          >
            {getCopy('links.login')}
          </Link>
        </Footer>
      </Form>
    </Page>
  );
};

const emailIsValid = email =>
  email.includes('@') && email.includes('.') && !email.includes(' ');
