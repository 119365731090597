import gql from 'graphql-tag';

export const GET_GOOGLE_LOGIN_URL = gql`
  query GoogleLoginUrl {
    googleLoginUrl(app: sphinx)
  }
`;

export const googleLoginUrlMock = {
  googleLoginUrl:
    'http://localhost:9090/google/authenticate?code=2390fej23932rf',
};
