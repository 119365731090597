import styled from 'styled-components';
import { BackLink } from 'components/back-link';

const HEADER_HEIGHT = '72px';
const MOBILE_HEADER_HEIGHT = '56px';

export const MobileBackLinkWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 56px;
  margin-left: 24px;
`;

export const MobileBackLink = styled(BackLink)`
  align-self: flex-end;
`;

export const MobileHeader = styled.div`
  /* max-width: 564px; */
`;

export const ContentWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-left: 28px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 536px;
  padding: 0 48px 0 20px;

  ${props => props.theme.media.small`
    width: 100%;
    height: calc(100% - ${MOBILE_HEADER_HEIGHT});
    padding: 40px 24px 24px;
    margin: auto;
  `}
`;

export const Nav = styled.nav`
  border-bottom: 2px solid black;
  border-bottom: ${props =>
    `1px solid ${props.theme.colors.background.controls}`};
  box-shadow: 0px 2px 4px ${props => props.theme.colors.background.secondary};
  width: 100%;
  height: ${HEADER_HEIGHT};
  padding: 16px 0 0 16px;
`;

export const Wrapper = styled.main`
  display: flex;
  align-items: flex-start;
  height: calc(100% - ${HEADER_HEIGHT});
  max-width: 1440px;
  margin: auto;
`;
