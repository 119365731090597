import gql from 'graphql-tag';

export const UPDATE_USER_PREFERENCES_MUTATION = gql`
  mutation UpdateUserPreferences($input: UpdateUserPreferencesInput!) {
    updateUserPreferences(input: $input) {
      id
    }
  }
`;

export const updateUserPreferencesMock = {
  updateUserPreferences: {
    id: 1,
  },
};
