import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import apolloLogger from 'apollo-link-logger';
import store from 'store';
import { captureError } from 'utils/tracking';

const httpLink = new HttpLink({
  credentials: 'include',
  headers: {
    Accept: 'application/json',
  },
  uri: `${process.env.ARCADIA_API_URL}/graphql`,
});

const parseApolloErrors = graphQLErrors => {
  const messages: string[] = [];
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      messages.push(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  return messages;
};

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const messages = parseApolloErrors(graphQLErrors);
  messages.forEach(message => {
    console.error(message); // eslint-disable-line no-console
    captureError({ error: new Error(message), operation });
  });
  if (networkError) captureError({ error: networkError, operation });
});

const links = [errorLink, httpLink];

if (import.meta.env.MODE !== 'production') links.unshift(apolloLogger);

// Preserve path if user is logged out of lovebird
const params = new URLSearchParams(window.location.search);
const { pathname } = location;
if (pathname !== '/authenticate/google')
  store.set('redirectPath', params.get('redirectPath'));

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
  name: 'Sphinx',
});
