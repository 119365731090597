import gql from 'graphql-tag';

export const APPLY_DORMANT_PROMO_CODE = gql`
  mutation ApplyDormantPromoCode($input: ApplyPromoCodeInput!) {
    applyDormantPromoCode(input: $input) {
      id
    }
  }
`;

export const applyDormantPromoCodeMock = {
  applyDormantPromoCode: {
    id: 2,
  },
};
