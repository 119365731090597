import React from 'react';
import { Link, Text, Icon } from '@arcadiapower/shrike';
import { copyFor, routes } from 'config';
import { Page } from 'components/page';
import { Wrapper } from 'styles/form.style';
import { GoogleButton } from './google-button';
import { Header } from './header';
import { Footer } from './footer';
import { Separator, Content } from './login.style';

const getCopy = copyFor('login');
const { emailLogin } = routes;

export const Login = () => {
  return (
    <Page>
      <Wrapper>
        <div>
          <Header />
          <Content>
            <GoogleButton />
            <Separator>
              <hr aria-hidden={true} />
              <Text textStyle="paragraph400">{getCopy('separator')}</Text>
              <hr aria-hidden={true} />
            </Separator>
            <Link to={emailLogin} type="CTA" fullWidth>
              <Icon
                icon="Envelope"
                margin={{ right: '8px' }}
                color="primaryInverse"
              />
              {getCopy('links.email')}
            </Link>
          </Content>
        </div>
        <Footer />
      </Wrapper>
    </Page>
  );
};
