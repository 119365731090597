import React from 'react';
import { useWindowSize } from 'react-use';
import { Logo, theme } from 'config';
import { BackLink } from 'components/back-link';
import { Banner } from 'components/banner';
import {
  Content,
  ContentWrapper,
  Nav,
  Wrapper,
  MobileBackLink,
  MobileBackLinkWrapper,
  MobileHeader,
} from './page.style';

const { breakpoints } = theme.media;

interface Back {
  to: string;
}
interface Props {
  back?: Back;
  children: React.ReactNode;
}

export const Page = ({ back, children }: Props) => {
  const { width } = useWindowSize();
  return width > breakpoints.small ? (
    <>
      <Banner />
      <Nav>
        <Logo />
      </Nav>
      <Wrapper>
        <ContentWrapper>
          {back && <BackLink to={back.to} />}
          <Content>{children}</Content>
        </ContentWrapper>
      </Wrapper>
    </>
  ) : (
    <>
      <MobileHeader>
        <Banner />
        <MobileBackLinkWrapper>
          {back && <MobileBackLink to={back.to} />}
        </MobileBackLinkWrapper>
      </MobileHeader>
      <Content>{children}</Content>
    </>
  );
};
