import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Alert, GoogleButton, Text } from '@arcadiapower/shrike';
import { copyFor, routes } from 'config';
import { Page } from 'components/page';
import { translateError } from 'utils/errors';
import { Footer, Wrapper } from 'styles/form.style';
import { UPDATE_USER_PREFERENCES_MUTATION } from './enable.api';
import { FooterLink } from './footer-link';
import { LinkWrapper } from './enable.style';

const getCopy = copyFor('googleLogin.enable');

interface EnabledProps {
  token: string | undefined;
  userId: number | undefined;
}
interface Props {
  isEnabled: (props: EnabledProps) => void;
  token: string | undefined;
  userId: number | undefined;
}

export const Enable = (props: Props) => {
  const { token, userId, isEnabled } = props;
  const [mutate, { data, loading, error }] = useMutation<
    UpdateUserPreferencesMutation,
    UpdateUserPreferencesMutationVariables
  >(UPDATE_USER_PREFERENCES_MUTATION);
  const { login } = routes;

  const enableGoogleLogin = () => {
    if (userId) {
      mutate({
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        variables: { input: { enabledGoogleSignIn: true, id: userId } },
      });
    }
  };

  useEffect(() => {
    isEnabled({ token, userId });
  }, [data]);

  return (
    <Page back={{ to: login }}>
      <Wrapper>
        <div>
          <Text tag="h1" textStyle="heading800" margin={{ bottom: '16px' }}>
            {getCopy('title')}
          </Text>
          <Text opacity="medium" margin={{ bottom: '48px' }}>
            {getCopy('subtitle')}
          </Text>
        </div>
        <Footer>
          <Alert margin={{ bottom: '16px' }}>{translateError(error)}</Alert>
          <GoogleButton
            fullWidth
            loading={loading}
            onClick={() => enableGoogleLogin()}
          >
            {getCopy('buttons.submit')}
          </GoogleButton>
          <br />
          <LinkWrapper>
            <FooterLink />
          </LinkWrapper>
        </Footer>
      </Wrapper>
    </Page>
  );
};
