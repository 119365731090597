/* eslint-disable max-classes-per-file */
import { copyFor } from 'config';

const getClientError = copyFor('errors.client');

export class ClientError extends Error {
  type: any;

  constructor(message: string) {
    super(message);
    this.type = 'client';
  }
}

// Use Object.setPrototypeOf(this, ClientError.prototype) for all errors that extend ClientError
// typescript/babel work around for using instanceof operator in utils/errors/translate-error; see https://stackoverflow.com/questions/42064466/instanceof-using-es6-class-inheritance-chain-doesnt-work
// see utils/errors/index line 10
export class TokenExpirationError extends ClientError {
  constructor() {
    super(getClientError('tokenExpirationError'));
    this.name = 'TokenExpirationError';
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export class InvalidEmailAddressError extends ClientError {
  constructor() {
    super(getClientError('invalidEmailAddressError'));
    this.name = 'InvalidEmailAddressError';
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}
