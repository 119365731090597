import gql from 'graphql-tag';

export const THIRD_PARTY_LOGIN = gql`
  query ThirdPartyUserLogin($code: String!, $state: String!) {
    thirdPartyUserLogin(
      app: sphinx
      platform: google
      accessCode: $code
      state: $state
    ) {
      id
      email
      token
    }
  }
`;

export const GOOGLE_LOGIN_ENABLED = gql`
  query GoogleLoginEnabled($id: Int!) {
    user(id: $id) {
      id
      email
      firstName
      preferences {
        enabledGoogleSignIn
      }
    }
  }
`;

export const thirdPartyUserLoginMock = token => ({
  thirdPartyUserLogin: {
    email: 'tester@ap.com',
    id: 1,
    token,
  },
});

export const googleLoginEnabledMock = (enabled = true) => ({
  user: {
    email: 'e@mail.com',
    firstName: 'Ernie',
    id: 1,
    preferences: {
      enabledGoogleSignIn: enabled,
    },
  },
});
