import { ApolloError } from '@apollo/client';
import { ClientError } from 'utils/errors/client-errors';
import { getServerError } from './server-errors';

export const translateError = (
  error?: ClientError | ApolloError | Error | null | string
) => {
  if (!error) {
    return null;
  } else if (error instanceof ClientError) {
    return error.message;
  } else {
    return getServerError(typeof error === 'string' ? error : error.message);
  }
};
