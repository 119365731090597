import React from 'react';
import { ApolloError } from '@apollo/client';
import { Link, Text } from '@arcadiapower/shrike';
import { copyFor, routes } from 'config';
import { translateError } from 'utils/errors';
import { Footer, Wrapper } from 'styles/form.style';
import { ClientError } from 'utils/errors/client-errors';
import { Page } from 'components/page';

const { login } = routes;
const getCopy = copyFor('googleLogin');
const getErrorCopy = copyFor('errors');

interface Props {
  error?: ApolloError;
  redirectError?: ApolloError | ClientError;
}

export const Error = ({ error, redirectError }: Props) => {
  return (
    <Page back={{ to: login }}>
      <Wrapper>
        <div>
          <Text textStyle="heading800" tag="h1" margin={{ bottom: '16px' }}>
            {getErrorCopy('header')}
          </Text>
          <Text margin={{ bottom: '40px' }}>
            {translateError(error || redirectError)}
          </Text>
          <Footer>
            <Link fullWidth to={login} type="CTA">
              {getCopy('links.login')}
            </Link>
          </Footer>
        </div>
      </Wrapper>
    </Page>
  );
};
