import styled from 'styled-components';

export const Banner = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  min-height: 52px;
  background: ${props => props.theme.colors.background[props.backgroundColor]};
  padding: 16px 24px;
  justify-content: center;
`;
