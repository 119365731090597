import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useAsync } from 'utils/hooks/use-async';
import { useSetState } from 'react-use';
import { copyFor, routes } from 'config';
import { identifyUserToThirdParties } from 'utils/tracking';
import { Text, Alert, Badge } from '@arcadiapower/shrike';
import { translateError } from 'utils/errors';
import { Page } from 'components/page';
import { Email } from 'components/fields/email';
import { Password } from 'components/fields/password';
import { useRedirect } from 'utils/hooks/use-redirect';
import { Form } from 'styles/form.style';
import { LOGIN } from './email-login.api';
import { Footer } from './footer';

const { login } = routes;
const getCopy = copyFor('emailLogin');

interface State {
  from: string;
}
interface Location {
  state: State;
}
interface Props {
  location: Location;
}

export const EmailLogin = (props: Props) => {
  const [{ email, password }, updateFields] = useSetState({
    email: '',
    password: '',
  });
  const client = useApolloClient();

  const [{ data: authToken, error, loading }, submit] = useAsync(async () => {
    const { data } = await client.query<LoginQuery, LoginQueryVariables>({
      query: LOGIN,
      variables: { email, password },
    });
    if (data?.userLogin)
      identifyUserToThirdParties({ email, id: data.userLogin.id });
    return data?.userLogin.token;
  });

  const handleSubmit = e => {
    e.preventDefault();
    submit();
  };

  const buttonDisabled = !email?.length || !password?.length;
  const redirectResult = useRedirect(authToken);
  const previousAppLocation = props.location?.state?.from;
  const errorMessage = translateError(error || redirectResult.error);

  return (
    <Page back={{ to: previousAppLocation || login }}>
      <Form onSubmit={handleSubmit}>
        <div>
          <Badge margin={{ bottom: '20px' }} backgroundColor="accent2">
            {getCopy('badge')}
          </Badge>
          <Text textStyle="heading800" tag="h1">
            {getCopy('title')}
          </Text>
          <Text margin={{ bottom: '40px' }} opacity="medium">
            {getCopy('description')}
          </Text>
          <Email onChange={email => updateFields({ email })} value={email} />
          <Password
            margin={{ top: '24px' }}
            onChange={password => updateFields({ password })}
            value={password}
          />
          {!errorMessage && <div style={{ height: '56px' }} />}
          <Alert margin="12px 0 20px">{errorMessage}</Alert>
        </div>
        <Footer
          disabled={buttonDisabled}
          loading={loading || redirectResult.loading}
        />
      </Form>
    </Page>
  );
};
